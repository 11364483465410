.icons{
  margin: 64px auto;
  display: flex;
  justify-content: space-between;
  &-item{
    display: flex;
    flex-direction: column;
    max-width: 288px;
    span{
      text-align: center;
      margin-top: 26px;
      color: $normal;
    }
  }
}
.main-slider{
  .slide{
    position: relative;
    img{
      margin: 0 15px;
      transition: all 1s ease-out;
    }
  }
  span{
    position: absolute;
    bottom: 16px;
    color: white;
    line-height: 26px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 20px 148px;
    background: #38aa37ab;
    letter-spacing: 1px;
  }
  img{
    box-sizing: border-box;
    height: 550px;
    width: 1100px;
    object-fit: cover;
  }
  .slick-prev,
  .slick-next{
    z-index: 99;
  }
  .slick-prev{
    left: 10px;
  }
  .slick-next{
    right: 10px;
  }
}

.promotions{
  a{
    text-decoration: none;
  }
  &__slider {
    overflow: hidden;
    margin-bottom: 33px;
  }
  &-title{
    font-family: 'AvenirNextCyrDemi', sans-serif;
    font-size: 74px;
    margin: 45px 0 50px 0;
  }
  &-category{
    margin-top: 28px;
    li{
      font-family: 'AvenirNextCyrDemi', sans-serif;
      font-size: 16px;
      line-height: 27px;
      color: $green;
      width: auto;
      padding: 5px 20px;
      &.active{
        color: #fff;
        background-color: $green;
        border-radius: 3px;
        span{
          border-bottom: none;
        }
      }
      &:hover{
        cursor: pointer;
        span{
          border-bottom: 2px dashed $green;
        }
      }
    }
  }
  &-cards{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 38px;
    &--item{
      max-width: 340px;
      .title{
        font-family: 'AvenirNextCyrDemi', sans-serif;
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 5px;
        color: #262525;
        margin-top: 12px;
        display: -webkit-box;
        /* autoprefixer: off */
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        overflow: hidden;
      }
      &:hover{
        cursor: pointer;
        img{
          transform: scale(1.1);
        }
        .title{
          opacity: 0.75;
        }
      }
      .description{
        font-family: 'AvenirNextCyrRegular', sans-serif;
        font-size: 14px;
        line-height: 20px;
        color: #000;
        display: -webkit-box;
        /* autoprefixer: off */
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        overflow: hidden;
      }
      .photo{
        width: 340px;
        height: 185px;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          transition: all 1s ease-out;
          object-fit: cover;
        }
      }
    }
  }
  &-pagination{
    display: flex;
    margin: 60px 0;
    justify-content: center;
    align-items: center;
    ul {
      li{
        display: inline-block;
        margin: 0 10px;
        a{
          font-family: 'AvenirNextCyrDemi', sans-serif;
          font-size: 16px;
          line-height: 1.69;
          text-align: center;
          color: #262525;
          text-decoration: none;
        }
        &:hover a,
        &.active a{
          color: #fa3435;
        }
      }
    }
    .arrow{
      height: 18px;
      align-self: center;
      img{
        width: 18px;
        display: inline-block;
        margin: 0 10px;
      }
      &.right img{
        transform: rotate(90deg);
      }
      &.left img{
        transform: rotate(90deg);
      }
    }
  }
}