$normal: #333;
$green: #38aa37;
$red: #CC3333;
$dark-red: #A92323;
$black: #333;
*{
    outline: none;
    font-family: 'PT Sans', Arial, serif;
    box-sizing: border-box;
}
html,
body {
    margin: 0;
    padding: 0;
    color: $normal;
}
h1,
h2,
h3,
h4,
h5,
h5 {
    margin: 0;
    padding: 0;
}
p,
ul {
    margin: 0;
    padding: 0;
}
ul {
    list-style: none;
}
a{
    text-decoration: none;
}
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
@font-face {
    font-family: 'PT Sans';
    src: local('PT Sans'), local('PTSans-Regular'), url('../fonts/pt-sans/ptsans.woff2') format('woff2'), url('../fonts/pt-sans/ptsans.woff') format('woff'), url('../fonts/pt-sans/ptsans.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'PT Sans';
    src: local('PT Sans Italic'), local('PTSans-Italic'), url('../fonts/pt-sans/ptsansitalic.woff2') format('woff2'), url('../fonts/pt-sans/ptsansitalic.woff') format('woff'), url('../fonts/pt-sans/ptsansitalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'PT Sans';
    src: local('PT Sans Bold'), local('PTSans-Bold'), url('../fonts/pt-sans/ptsansbold.woff2') format('woff2'), url('../fonts/pt-sans/ptsansbold.woff') format('woff'), url('../fonts/pt-sans/ptsansbold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'PT Sans';
    src: local('PT Sans Bold Italic'), local('PTSans-BoldItalic'), url('../fonts/pt-sans/ptsansbolditalic.woff2') format('woff2'), url('../fonts/pt-sans/ptsansbolditalic.woff') format('woff'), url('../fonts/pt-sans/ptsansbolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
.wrap{
    width: 1100px;
    margin: 0 auto;
}
.button{
    border: 2px solid $dark-red;
    background: none;
    border-radius: 1px;
    padding: 15px 40px;
    font-size: 16px;
    &:hover{
        border: 2px solid $normal;
        cursor: pointer;
    }
    &:active{
        background: $dark-red;
        color: #fff;
        border: 2px solid $dark-red;
    }
}
.button2{
    background: $red;
    color: white;
    font-size: 16px;
    text-align: center;
    padding: 10px 40px;
    border: none;
    &:hover{
        background: $dark-red;
        cursor: pointer;
        &:active{
            background: $black;
        }
    }
}

.page-title{
    color: $green;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin: 60px auto 28px auto;
}
