.header{
  position: relative;
}
.logo{
  position: absolute;
  width: 128px;
  left: calc(50% - 64px);
}
.header-top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  span{
    font-size: 22px;
    color: $green;
    font-weight: bold;
  }
  .phone{
    display: flex;
    justify-content: space-between;
    align-items: center;
    a{
      color: #38aa37;
      font-size: 18px;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      span{
        font-size: 12px;
        font-weight: normal;
        color: $normal;
      }
    }
    img{
      padding: 0 16px;
    }
  }
  border-bottom: 1px solid $normal;
}
.main-menu-desktop{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  .menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
  }
  .mail{
    text-align: right;
    display: flex;
    align-items: center;
    img{
      padding: 0 16px;
    }
  }
  li{
    a{
      color: $normal;
      padding: 16px 0;
      font-size: 14px;
      text-transform: uppercase;
      &:hover{
        color: $red;
      }
    }
  }
}